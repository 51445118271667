import {createAction} from '@reduxjs/toolkit'
import {CreateBookmarkResponse, DeleteBookmarkResponse} from '@wix/ambassador-wix-events-agenda/types'
import {EventStatus} from '@wix/ambassador-wix-events-web/types'
import {isUserLoggedIn} from '../selectors/member'
import {createAsyncAction} from '../services/redux-toolkit'
import {ScheduleResponse} from '../types'
import {promptLogin} from './member'
import {togglePersonalAgendaModal} from './modals'

export const getSchedule = createAsyncAction<ScheduleResponse>('GET_SCHEDULE', (_, {extra, getState}) => {
  const state = getState()
  const eventId = state.event.id
  const draft = state.event.status === EventStatus.DRAFT
  const {location, tags} = state.filter
  const locale = extra.controllerParams.controllerConfig.wixCodeApi?.site?.regionalSettings ?? 'en-US'

  return extra.api.getSchedule(eventId, location, tags, locale, draft)
})

export const getDemoSchedule = createAsyncAction<ScheduleResponse, {timeZoneId: string; language: string}>(
  'GET_DEMO_SCHEDULE',
  async ({timeZoneId, language}, {extra: {api}}) => api.getDemoSchedule(timeZoneId, language),
)

export const getBookmarks = createAsyncAction('GET_BOOKMARKS', (_, {extra: {api}, getState}) =>
  api.getBookmarks(getState().event.id),
)

export const createBookmark = createAsyncAction<CreateBookmarkResponse, HandleBookmarkArgs>(
  'CREATE_BOOKMARK',
  ({itemId}, {extra: {api}, getState}) => api.createBookmark(getState().event.id, itemId),
  {
    condition: (_, {getState}) => {
      const state = getState()
      return !state.schedule.bookmarking
    },
  },
)

export const deleteBookmark = createAsyncAction<DeleteBookmarkResponse, HandleBookmarkArgs>(
  'DELETE_BOOKMARK',
  ({itemId}, {extra: {api}, getState}) => api.deleteBookmark(getState().event.id, itemId),
  {
    condition: (_, {getState}) => {
      const state = getState()
      return !state.schedule.bookmarking
    },
  },
)

export const readDescriptionClicked = createAction<{itemId: string}>('READ_DESCRIPTION_CLICKED')

export const viewPersonalAgenda = createAsyncAction<void, {origin: PersonalAgendaOrigin}>(
  'VIEW_PERSONAL_AGENDA',
  async (_, {getState, dispatch}) => {
    const loggedIn = isUserLoggedIn(getState().member)

    if (loggedIn) {
      dispatch(togglePersonalAgendaModal())
    } else {
      dispatch(promptLogin())
    }
  },
)

export const GetBookmarks = typeof getBookmarks
export type ReadDescriptionClicked = typeof readDescriptionClicked
export type CreateBookmark = typeof createBookmark
export type DeleteBookmark = typeof deleteBookmark
export type ViewPersonalAgenda = typeof viewPersonalAgenda

interface HandleBookmarkArgs {
  itemId: string
  origin: BookmarkOrigin
}

export enum BookmarkOrigin {
  Schedule = 'schedule',
  ItemDetails = 'item details', // TODO: use this when schedule item page is available
  MyAgenda = 'my agenda',
}

export enum PersonalAgendaOrigin {
  Schedule = 'schedule',
  ItemDetails = 'item details', // TODO: use this when schedule item page is available
}
