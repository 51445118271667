import React from 'react'
import {Text} from 'wix-ui-tpa/Text'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import classNames from 'classnames'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useWidgetProps} from '../../../../hooks/widget-props'
import s from './empty-personal-agenda.scss'
import {classes as st} from './empty-personal-agenda.st.css'

export const EmptyPersonalAgenda = () => {
  const {t} = useWidgetProps()
  const {isMobile} = useEnvironment()

  return (
    <div className={classNames(s.text, {[s.mobile]: isMobile})} data-hook={DH.PERSONAL_AGENDA_EMPTY_STATE}>
      <Text className={st.text}>{t('myAgenda.modal.emptyStateText')}</Text>
    </div>
  )
}
