import React from 'react'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Star, StarFilled} from '../../../icons'
import {isUserLoggedIn} from '../../../../selectors/member'
import {useWidgetProps} from '../../../../hooks/widget-props'
import {useColors} from '../../../../hooks/colors'
import {BookmarkOrigin} from '../../../../actions/schedule'
import {useModal} from '../../../../hooks/modal'
import s from './bookmark.scss'
import {BookmarkProps} from '.'

export const Bookmark = ({itemId, bookmarked}: BookmarkProps) => {
  const {insideModal} = useModal()
  const {personalAgendaEnabled, createBookmark, deleteBookmark, promptLogin, member, closeModal} = useWidgetProps()
  const {isMobile} = useEnvironment()
  const {isDarkLinkColor} = useColors()
  const wired = isDarkLinkColor()

  if (!personalAgendaEnabled) {
    return null
  }

  const handleClick = () => {
    const loggedIn = isUserLoggedIn(member)

    if (loggedIn) {
      let origin = BookmarkOrigin.Schedule

      if (insideModal) {
        origin = BookmarkOrigin.MyAgenda
      } else if (isMobile) {
        origin = BookmarkOrigin.ItemDetails
      }

      const req = {
        itemId,
        origin,
      }
      bookmarked ? deleteBookmark(req) : createBookmark(req)
    } else {
      promptLogin(itemId)
      closeModal()
    }
  }

  return (
    <div className={classNames(s.actions, {[s.insideModal]: insideModal, [s.wired]: wired})}>
      {bookmarked ? (
        <StarFilled dataHook={DH.DELETE_BOOKMARK} className={s.starFilled} onClick={handleClick} />
      ) : (
        <Star dataHook={DH.CREATE_BOOKMARK} className={s.star} onClick={handleClick} />
      )}
    </div>
  )
}
