import {isColorDark} from '@wix/wix-events-commons-statics'
import {useWidgetProps} from './widget-props'

export const useColors = () => {
  const widgetProps = useWidgetProps()

  const getActionColor = () =>
    widgetProps.host.style.siteColors.find((color: any) => color.reference === 'color-8').value

  const getLinkColor = () => widgetProps.host.style.styleParams.colors?.readMoreTextColor?.value || getActionColor()

  const isDarkActionColor = () => isColorDark(getActionColor())

  const isDarkLinkColor = () => isColorDark(getLinkColor())

  return {
    isDarkActionColor,
    isDarkLinkColor,
  }
}
