import React from 'react'
import classNames from 'classnames'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {useModal} from '../../hooks/modal'
import {DateDivider} from './divider'
import {classes as c} from './schedule.st.css'
import {DesktopItem, MobileItem} from './item'
import {Time} from './time'
import {ScheduleProps} from './interfaces'

export const Schedule = ({days}: ScheduleProps) => {
  const {isMobile} = useEnvironment()
  const {insideModal} = useModal()

  const Item = isMobile ? MobileItem : DesktopItem

  return (
    <div className={classNames(c.agenda, {[c.mobile]: isMobile, [c.insideModal]: insideModal})}>
      {Object.entries(days).map(([key, day]) => (
        <div key={key} className={c.day}>
          {insideModal ? (
            <h3 className={c.dayTitle}>
              <Time dateTime={day.title}>{day.title}</Time>
            </h3>
          ) : (
            <h2 className={c.dayTitle}>
              <Time dateTime={day.title}>{day.title}</Time>
            </h2>
          )}
          <DateDivider />
          <ul>
            {day.items.map((item, index) => (
              <Item item={item} showDivider={index !== day.items.length - 1} key={index} />
            ))}
          </ul>
        </div>
      ))}
    </div>
  )
}
