import React from 'react'
import {ChevronRight} from 'wix-ui-icons-common'
import {useSettings} from '@wix/yoshi-flow-editor/tpa-settings/react'
import {useEnvironment} from '@wix/yoshi-flow-editor'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {getFilterButtonText} from '../../selectors/filter'
import settingsParams from '../../settingsParams'
import {useWidgetProps} from '../../hooks/widget-props'
import s from './filters.scss'
import {Locations} from './locations'
import {Tags} from './tags'

export const Filters = () => {
  const {t, selectedLocation, setLocation, locations, tags, toggleTag, filter, toggleFiltersModal} = useWidgetProps()
  const settings = useSettings()
  const {isMobile} = useEnvironment()
  const showFilters = settings.get(settingsParams.showFilters)

  if (!showFilters) {
    return isMobile ? null : <div className={isMobile ? null : s.hiddenFiltersSpacing} />
  }

  if (isMobile) {
    return (
      <button data-hook={DH.MOBILE_FILTER_BUTTON} className={s.mobileFilterButton} onClick={() => toggleFiltersModal()}>
        <span className={s.mobileFilterButtonText}>{getFilterButtonText(filter, t)}</span>
        <ChevronRight />
      </button>
    )
  }

  return (
    <div className={s.container} data-hook={DH.FILTERS_SECTION}>
      <Locations locations={locations} t={t} selectedLocationId={selectedLocation} onClick={setLocation} />
      <Tags filters={true} t={t} tags={tags} onClick={toggleTag} />
    </div>
  )
}
