import {createSettingsParams} from '@wix/yoshi-flow-editor/tpa-settings'

export type ISettingsParams = {
  scheduleTitleText: string
  buttonText: string
  readMore: string
  readLess: string
  showDateAndLocation: boolean
  showButton: boolean
  showFilters: boolean
  showDuration: boolean
  buttonStyle: number
  headerTextAlignment: number
}

export enum HEADER_TEXT_ALIGNMENT {
  LEFT = 1,
  CENTER = 2,
  RIGHT = 3,
}

export enum BUTTON_STYLE {
  HOLLOW = 1,
  HOLLOW_ROUNDED = 2,
  FULL = 3,
  FULL_ROUNDED = 4,
}

export const isFullButtonStyle = (buttonStyle: BUTTON_STYLE) =>
  [BUTTON_STYLE.FULL, BUTTON_STYLE.FULL_ROUNDED].includes(buttonStyle)

export const isRoundedButtonStyle = (buttonStyle: BUTTON_STYLE) =>
  [BUTTON_STYLE.HOLLOW_ROUNDED, BUTTON_STYLE.FULL_ROUNDED].includes(buttonStyle)

const settingsParams = createSettingsParams<ISettingsParams>({
  scheduleTitleText: {
    getDefaultValue: ({t}) => t('defaults.scheduleTitleText'),
  },
  buttonText: {
    getDefaultValue: ({t}) => t('defaults.buttonText'),
  },
  readMore: {
    getDefaultValue: ({t}) => t('defaults.readMoreText'),
  },
  readLess: {
    getDefaultValue: ({t}) => t('defaults.readLessText'),
  },
  showDateAndLocation: {
    getDefaultValue: () => true,
  },
  showFilters: {
    getDefaultValue: () => true,
  },
  showButton: {
    getDefaultValue: () => true,
  },
  showDuration: {
    getDefaultValue: () => true,
  },
  buttonStyle: {
    getDefaultValue: () => BUTTON_STYLE.HOLLOW,
  },
  headerTextAlignment: {
    getDefaultValue: () => HEADER_TEXT_ALIGNMENT.LEFT,
  },
})

export default settingsParams
