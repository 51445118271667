import {asyncActionsFactory} from '@wix/wix-events-commons-statics'
import {ControllerParams} from '@wix/yoshi-flow-editor'
import {State} from '../reducers'
import {Api} from '../api/api'

type Extra = {
  api: Api
  controllerParams: ControllerParams
}

export const createAsyncAction = asyncActionsFactory<State, Extra>()
