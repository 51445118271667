import React from 'react'

interface StarFilledProps {
  className?: string
  dataHook?: string
  onClick?: () => void
}

export const StarFilled = ({className, dataHook, onClick = () => {}}: StarFilledProps) => (
  <svg
    className={className}
    data-hook={dataHook}
    onClick={onClick}
    width="20px"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
  >
    <g id="Layer_2" data-name="Layer 2">
      <g id="Artboards">
        <rect fill="none" width="24" height="24" />
      </g>
      <g id="Basic_Shapes" data-name="Basic Shapes">
        <path
          stroke="#000"
          stroke-miterlimit="10"
          d="M12.45,3.23l2.24,4.53a.51.51,0,0,0,.37.28l5,.73a.5.5,0,0,1,.28.85l-3.63,3.53a.51.51,0,0,0-.14.44l.86,5a.5.5,0,0,1-.73.53l-4.48-2.36a.47.47,0,0,0-.46,0L7.29,19.11a.5.5,0,0,1-.73-.53l.86-5a.49.49,0,0,0-.15-.44L3.65,9.62a.5.5,0,0,1,.28-.85l5-.73a.51.51,0,0,0,.37-.28l2.24-4.53A.5.5,0,0,1,12.45,3.23Z"
        />
      </g>
    </g>
  </svg>
)
