import React, {useState} from 'react'
import {ALIGNMENT, SIZE, SKIN} from 'wix-ui-tpa/dist/src/components/Tags/constants'
import {Tags as WixTags} from 'wix-ui-tpa/Tags'
import {TextButton, TEXT_BUTTON_PRIORITY} from 'wix-ui-tpa/TextButton'
import classNames from 'classnames'
import {scheduleDataHooks as DH} from '@wix/wix-events-data-hooks'
import {useModal} from '../../../hooks/modal'
import {classes as c} from './tags.st.css'
import {TagsProps} from './index'

const MAX_VISIBLE_TAGS = 10

export const Tags = ({onClick, tags, className, t, filters}: TagsProps) => {
  const [expanded, setExpanded] = useState(false)
  const {insideModal} = useModal()

  if (!tags.length) {
    return null
  }

  return (
    <div className={classNames(className, {[c.insideModal]: insideModal, [c.default]: !insideModal})}>
      <WixTags
        onClick={tag => onClick(tag.value)}
        items={expanded || !filters ? tags : tags.slice(0, MAX_VISIBLE_TAGS)}
        size={SIZE.small}
        skin={SKIN.light}
        alignment={ALIGNMENT.left}
        className={c.tags}
      />
      {filters && tags.length > MAX_VISIBLE_TAGS ? (
        <TextButton
          className={c.seeAllButton}
          data-hook={DH.TAGS_SHOW_ALL_BUTTON}
          priority={TEXT_BUTTON_PRIORITY.primary}
          onClick={() => setExpanded(!expanded)}
        >
          {t(expanded ? 'showLess' : 'showAll')}
        </TextButton>
      ) : null}
    </div>
  )
}
